import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'


const query = graphql`
{
  allStrapiBlog {
    nodes {
      title
      slug
      image {
        alternativeText
        localFile {
          url
        }
      }
    }
  }
}

`

const BlogCard = () => {
    const data = useStaticQuery(query)

    const {allStrapiBlog: {nodes}} = data

    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">

                    {nodes.map((blog,idx)=>{

                        return(
                            <div key={idx} className="col-lg-4 col-md-6">
                                <div className="single-blog-post bg-fffbf5">
                                    <div className="post-image">
                                        <Link to={`/blog/${blog.slug}`}>
                                            <img src={blog.image.localFile.url} alt={blog.image.alternativeText} />
                                        </Link>
                                    </div>

                                    <div className="post-content">
                                        <h3>
                                            <Link to={`/blog/${blog.slug}`}>
                                                {blog.title}
                                            </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )

                    })}

                </div>
            </div>
        </div>
    )
}

export default BlogCard
