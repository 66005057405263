import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'


const query = graphql`
{
  strapiSettings {
    home_breadcrumb
  }
}
`


const PageBanner = ({pageTitle, homePageUrl, activePageText}) => {

    const data = useStaticQuery(query)

    const {
        strapiSettings: {home_breadcrumb}
    } = data


    return (
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul>
                        <li>
                            <Link to={homePageUrl}>
                                {home_breadcrumb}
                            </Link>
                        </li>
                        <li>{activePageText}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}




export default PageBanner;
